export const WORDS = new Map([
  ['2023-02-13 09:00', 'earth'],
  ['2023-02-15 09:00', 'radar'],
  ['2023-02-16 12:00', 'pixel'],
  ['2023-02-20 08:00', 'error'],
  ['2023-02-22 09:00', 'cubic'],
  ['2023-02-23 12:00', 'gamma'],
  ['2023-02-27 08:00', 'pilot'],
  ['2023-03-01 09:00', 'scale'],
  ['2023-03-02 12:00', 'lossy'],
  ['2023-03-06 08:00', 'hertz'],
  ['2023-03-08 09:00', 'plane'],
  ['2023-03-09 12:00', 'ozone'],
  ['2023-03-13 08:00', 'kappa'],
  ['2023-03-15 09:00', 'radio'],
  ['2023-03-16 12:00', 'metal'],
  ['2023-03-20 08:00', 'drone'],
  ['2023-03-22 09:00', 'orbit'],
  ['2023-03-23 12:00', 'polar'],
  ['2023-03-27 08:00', 'plant'],
  ['2023-03-29 09:00', 'image'],
  ['2023-03-30 12:00', 'angle'],
  ['2023-04-12 09:00', 'water'],
  ['2023-04-13 12:00', 'trend'],
  ['2023-04-14 08:00', 'range'],
  ['2023-04-17 08:00', 'laser'],
  ['2023-04-19 09:00', 'solar'],
  ['2023-04-20 12:00', 'space'],
  ['2023-04-24 08:00', 'ratio'],
  ['2023-04-26 09:00', 'curve'],
  ['2023-05-03 09:00', 'lidar'],
  ['2023-05-04 12:00', 'nadir'],
  ['2023-05-08 08:00', 'ocean'],
  ['2023-05-10 09:00', 'phase'],
  ['2023-05-11 12:00', 'urban'],
  ['2023-05-15 08:00', 'index'],
  ['2023-05-17 09:00', 'pulse'],
  ['2023-05-18 12:00', 'rotor'],
])
